var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ct-centered-spinner-component",
      class: { overlay: _vm.overlay },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "spinner-content d-flex justify-content-center align-items-center",
          style: _vm.computedStyles,
        },
        [
          _c("spinner", {
            style: `width: ${_vm.height}; height: ${_vm.height};`,
            attrs: {
              height: _vm.svgHeight,
              width: _vm.svgWidth,
              label: "Loading...",
            },
          }),
          _c("div", { staticClass: "spinner-slot" }, [_vm._t("default")], 2),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }